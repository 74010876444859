import React from "react";
// import imgHeroBackground from "../images/hero_bg.jpg";
// import excelconLogoWhite from "../images/logos/logo-gnb.svg";
import { css } from "@emotion/react";
import imgHeroBackground from "../images/hero-bg.jpg";

const PageHeroStyle = css`
  background-image: url(${imgHeroBackground});
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 4.5rem;
  height: 40rem;

  .page__hero-content {
    width: 100%;
  }

  .page__hero-content > h1 {
    font-size: 3rem;
    margin-bottom: 1.56rem;
    font-weight: 800;
  }

  .page__hero-content > p {
    font-size: 2rem;
    font-weight: 100;
  }

  @media (max-width: 768px) {
    height: 30rem;

    .page__hero-content > h1 {
      font-size: 2.5rem;
    }

    .page__hero-content > p {
      font-size: 0.875rem;
    }
  }
`;

export const PageHero = ({ children }) => {
  return (
    <div className="page__hero" css={PageHeroStyle}>
      <div className="page__hero-content container">{children}</div>
    </div>
  );
};
