import React from "react";
import { css, jsx } from "@emotion/react";
import { SectionStyle } from "../../styles/common";

const FoodSectionStyle = css`
  ${SectionStyle}
  padding: 120px 0;
  background-color: #171717;

  .food-contents__title {
    margin: 0;

    font-size: 40px;
    line-height: 47.73px;
    font-weight: 700;
  }

  .food-contents__title--below {
    margin-top: 240px;
  }

  .food-contents__subtitle {
    margin: 50px 0 0;

    font-size: 24px;
    line-height: 29px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
  }

  .food-content {
    margin-top: 90px;
  }

  .food-content__title {
    margin: 0 0 30px;

    font-size: 32px;
    line-height: 38px;
    font-weight: 700;
  }

  .food-content__title--light {
    font-weight: 600;
  }

  .food-content__foods {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .food-content__food {
    border-radius: 8px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: #E6E6E6;
    color: black;
  }

  .food-content__food img {
    width: 100%;
    object-fit: cover;
  }

  .food-content__food--span {
    grid-column: span 2;
  }

  .food-content__food-title {
    margin: 20px 0;

    font-size: 24px;
    line-height: 29px;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    .food-contents__title {
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: -0.01em;
    }

    .food-contents__title--below {
      margin-top: 90px;
    }

    .food-contents__subtitle {
      margin: 20px 0 0;

      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.01em;
    }

    .food-content {
      margin-top: 50px;
    }

    .food-content__title {
      margin: 0 0 15px;

      font-size: 20px;
      line-height: 24px;
    }

    .food-content__foods {
      grid-gap: 10px;
      grid-template-columns: 1fr;
    }

    .food-content__foods--mobile-two-col {
      grid-template-columns: 1fr 1fr;
    }

    .food-content__food img {
      max-height: 160px;
    }

    .food-content__foods--mobile-two-col img {
      max-height: initial;
    }

    .food-content__food-title {
      margin: 12px 0;

      font-size: 14px;
      line-height: 17px;
    }
  }
`;

const foodsRooftop = [
  {
    title: "Roast Beef",
    // image: imgFoodRoastBeef,
  },
  {
    title: "Spare Rib",
    // image: imgFoodSpareRib,
  },
  {
    title: "Pulled Beef",
    // image: imgFoodPulledBeef,
  },
  {
    title: "Chicken BBQ",
    // image: imgFoodChickenBBQ,
  },
  {
    title: "Grilled Vege",
    // image: imgFoodGrilledVege,
  },
]

const foodsFirstFloor = [
  "Meatball Pasta",
  "Mini Slider",
  "Antipasto Skewer",
  "Bruschetta",
  "Croissan Sandwich",
  "Canape",
  "Fruits Cup",
  "Waffle",
]

const drinks = [
  "Welcome Drink _ Excel Beef Beer",
  "Whisky",
  "EXCELCON Signature cocktails",
  "Non-alcoholic cocktails",
]


export const  ContentsPageFoodSection: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <section css={FoodSectionStyle}>
      <div className="container food-contents">
        <h2 className="food-contents__title"><span className="text-secondary">엑셀플레이스</span>에서 선보이는<br />새로운 메뉴</h2>
        <h3 className="food-contents__subtitle">항상 최고의 맛을 보장하는 엑셀플레이스에서 엑셀콘을 위해 새로운 메뉴를 선보입니다! 바베큐, 샐러드 등 <strong>다양한 음식을 마음껏 즐기세요!</strong></h3>
        <div className="food-content">
          <h4 className="food-content__title">루프탑 <span className="text-secondary">American Live BBQ</span></h4>
          <div className="food-content__foods">
            {foodsRooftop.map((food) => (
              <div className="food-content__food" key={food.title}>
                {/* <img src={food.image} alt={food.title} /> */}
                <h5 className="food-content__food-title">{food.title}</h5>
              </div>
            ))}
          </div>
        </div>
        <div className="food-content">
          <h4 className="food-content__title">1층 <span className="text-secondary">Beef Bar</span></h4>
          <div className="food-content__foods food-content__foods--mobile-two-col">
            {foodsFirstFloor.map((food) => (
              <div className="food-content__food" key={food}>
                <h5 className="food-content__food-title">{food}</h5>
              </div>
            ))}
          </div>
        </div>

        <h2 className="food-contents__title food-contents__title--below">입장과 함께 제공 되는 <span className="text-secondary">웰컴드링크</span>와 <br />맛있는 칵테일과 위스키</h2>
        <h3 className="food-contents__subtitle">엑셀플레이스는 스테이크도 유명하지만 맛있는 위스키와 칵테일로도 유명합니다. <br />입장과 함께 제공 되는 웰컴드링크 한 잔을 다 즐기셨다면, 맛있는 칵테일과 위스키를 잔술로 구매하실 수 있습니다!</h3>

        <div className="food-content">
          <h4 className="food-content__title food-content__title--light text-secondary">Beverage</h4>
          <div className="food-content__foods food-content__foods--mobile-two-col">
            <div className="food-content__food">
              {/* <img src={imgDrink1} alt="Excel Beef Beer 1" /> */}
            </div>
            <div className="food-content__food">
              {/* <img src={imgDrink2} alt="Excel Beef Beer 2" /> */}
            </div>
            {drinks.map((food, i) => (
              <div className="food-content__food food-content__food--span" key={food}>
                <h5 className="food-content__food-title">{(
                  i === 0 ? <strong>{food}</strong> : food
                )}</h5>
              </div>
            ))}
          </div>
        </div>

      </div>
    </section>
  );
}
