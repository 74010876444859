import React, { useEffect } from "react";
import { css, jsx } from "@emotion/react";
import { SectionStyle } from "../../styles/common";


const TimetableSectionStyle = css`
  ${SectionStyle}
  padding: 120px 0;
  background-color: #1f1f1f;

  h2 {
    margin: 0;

    font-size: 40px;
    line-height: 47.73px;
    font-weight: 400;
  }

  .timetable__image--mobile {
    display: none;
    width: 100%;
  }
  .timetable__image--desktop {
    display: initial;
  }

  @media (max-width: 768px) {

    h2 {
      margin-bottom: 30px;

      font-size: 24px;
      line-height: 29px;
      font-weight: 700;
      letter-spacing: -0.01em;
    }

    .timetable__image--mobile {
      display: initial;
    }
    .timetable__image--desktop {
      display: none;
    }
  }
`;



export const ContentsPageTimetableSection: React.FC<React.PropsWithChildren<{ timetableRef?: React.Ref<HTMLDivElement> }>> = (props) => {
  return (
    <section css={TimetableSectionStyle}>
      <div className="container">
        <h2 ref={props.timetableRef}>시간표</h2>
        {/* <img src={contentsTimetable} alt="time table content" className="timetable__image--desktop" />
        <img src={contentsTimetableMobile} alt="time table content" className="timetable__image--mobile" /> */}
      </div>
    </section>
  );
}
